import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, LinearProgress, Typography } from '@mui/material';

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');

  const fileSelectedHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const fileUploadHandler = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    axios.post('https://backendvideo.mohammedbenchaa.com/upload/video', formData, {
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(progress);
      },
    })
    .then((response) => {
      setUploadStatus('File uploaded successfully.');
    })
    .catch((error) => {
      setUploadStatus('File upload failed.');
    });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: '0 auto', textAlign: 'center', padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Video Upload
      </Typography>
      <input
        type="file"
        onChange={fileSelectedHandler}
        style={{ marginBottom: 20 }}
      />
      <Box sx={{ marginBottom: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={fileUploadHandler}
          disabled={!selectedFile}
        >
          Upload
        </Button>
      </Box>
      {uploadProgress > 0 && (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" color="textSecondary">
            {uploadProgress}%
          </Typography>
        </Box>
      )}
      {uploadStatus && (
        <Typography variant="body2" color="textSecondary">
          {uploadStatus}
        </Typography>
      )}
    </Box>
  );
};

export default FileUpload;
